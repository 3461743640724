<template>
	<div>
		<login-header :loginTitle="''"></login-header>
		<router-view></router-view>
		<login-footer></login-footer>
	</div>
	
</template>

<script>
	import loginHeader from '@/components/Login/loginHeader.vue';
	import loginFooter from '@/components/Login/loginFooter.vue'
	export default {
		components: {
			loginHeader,
			loginFooter
		},
		data() {
			return {
				
			}
		},
		created() {
		  
		},
		mounted() {
		  
		},
		methods: {
			
		}
	}
</script>

<style>
</style>